<template>
	<div ref="child">
		<v-row align="center" class="mb-3">
			<v-col cols="auto" class="pl-2 pt-0 pr-1">
				<v-icon v-if="edit" style="cursor: grab;" class="handle" size="18">mdi-drag-horizontal-variant</v-icon>
				<v-icon v-else size="20">mdi-playlist-plus</v-icon>
			</v-col>
			<v-col class="sub-title-2 pt-0 font-weight-bold">{{ $tc('global.name.extra_fields', 2) }}</v-col>
			<v-col cols="auto" v-if="update" class="py-0">
				<Button iconVal="mdi-plus" carre iconSize="18" color="primary" :click="() => dialog = true" :toolTip="$t('single_trad.ExtraFields.add')" />
			</v-col>
		</v-row>
		<v-row>
			<v-col v-for="(extra, i) in extras" :key="i" style="min-width: 290px" class="py-0 px-2">
				<Field
						v-model="extras[i].value"
						:field="{label: extra.name, required: extra.required, type: extra.type}"
						:pevents="pevents"
						:path="path + '/extra'"
						:pathId="extra.id"
						:extras="true"
						:oldVal="old_extras[i].value"
						fieldName="value"
						:mode="update && !extras[i].__meta__.locked ? 'edition':'readonly'"
						@ready="ready--"
				/>
			</v-col>
		</v-row>

		<Dialog v-model="dialog" :title="$t('single_trad.ExtraFields.dialog')" @close="dialog = false" width="640">
			<template v-slot:content>
				<v-row no-gutters class="pt-3 px-2">
					<v-col cols="12" sm="6" :class="$vuetify.breakpoint.width >= 600 ? '':'mb-5 mt-1'">
						<div v-if="extras.length > 0">
							<v-list dense class="transparent" v-if="$vuetify.breakpoint.width >= 600">
								<v-list-item
										v-for="(item, i) in extras"
										:key="i"
										class="rounded-lg overflow-hidden"
										@click="item === selected_extra ? () => {selected_extra = {}; fields = $tools.filterObject(all_fields, e => !e.read_only)} : () => { selected_extra = item; fields = JSON.parse(JSON.stringify(all_fields)) }"
										:style="item === selected_extra ? {backgroundColor: $tools.transparentize('primary', 0.2, $vuetify)}:{}"
								>
									<v-list-item-content>
										<v-list-item-content class="paragraph" :class="item === selected_extra ? 'primary--text':''">{{ item.name }}</v-list-item-content>
									</v-list-item-content>
									<v-list-item-action>
										<Button iconVal="mdi-close" icon small iconSmall :click="() => {confirm_dialog = true; item2delete = item}" />
									</v-list-item-action>
								</v-list-item>
							</v-list>

							<Select v-else v-model="selected_extra" :items="extras" label="Choose one" class="ma-3" clearable />
						</div>
						<div v-else class="sub-title-2 pl-2">{{ $t('single_trad.ExtraFields.no_e') }}</div>
					</v-col>
					<v-divider vertical class="mb-3 mx-2" v-if="$vuetify.breakpoint.width >= 600" />
					<v-col>
						<div class="sub-title-2 px-2">{{ $t('single_trad.ExtraFields.add_1') }}</div>
						<Field
								v-if="key !== 'display_name' && key !== 'id' && key !== 'value'"
								v-model="selected_extra[key]"
								v-for="(field, key, i) in fields"
								:key="i"
								:field="field"
								:pevents="pevents"
								:fieldName="key"
								mode="creation"
								@change="validateCreation()"
						/>
						<v-row class="mx-0">
							<v-col class="pr-0">
								<Button
										:disabled="item_empty"
										block
										color="grey darken-1"
										:dark="!item_empty"
										large
										:label="$tc('global.action.clear', 1)"
										:click="() => { selected_extra = {}; item_empty = true; validateCreation() }"
								/>
							</v-col>
							<v-col>
								<Button
										:loading="loading"
										:disabled="!creation_ok"
										block
										color="primary"
										large
										:label="$tc('global.action.validate', 1)"
										:click="() => selected_extra.id ? saveExtraField() : createExtraField()"
								/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</template>
		</Dialog>

		<Dialog
				v-model="confirm_dialog"
				type="confirm"
				:message=" $tc('single_trad.Table.dialog_v_delete', 1, {item: item2delete ? item2delete.name : ''})"
				@cancel="confirm_dialog = false;"
				@confirm="()=> deleteExtraField()"
				:loading="loading"
		/>
	</div>
</template>

<script>
	export default {
		name: "ExtraFieldGroup",
		props: ['value', 'path', 'pevents', 'edit', 'update'],
		components: {
			Field: () => import('@/components/Field.vue'),
			Button: () => import('@/components/ui/Button.vue'),
			Select: () => import('@/components/fields/Select.vue'),
			Dialog: () => import('@/components/ui/Dialog.vue')
		},
		data(){
			return {
				dialog: false,
				all_fields: {},
				fields: {},
				selected_extra: {},
				item_empty: true,
				creation_ok: false,
				loading: false,
				confirm_dialog: false,
				item2delete: null,
				ready: -1
			}
		},
		computed: {
			extras: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			},
			old_extras(){
				return JSON.parse(JSON.stringify(this.extras))
			}
		},
		watch: {
			selected_extra(val){
				if(val === null){
					this.selected_extra = {}
					this.fields = this.$tools.filterObject(this.all_fields, e => !e.read_only)
				}

				this.validateCreation()
			},
			ready(val){
				if(val === 0){
					this.$emit('loaded',  this.$refs.child)
				}
			}
		},
		methods: {
			createExtraField(){
				this.loading = true
				this.$wsc.createItem(this.path + '/extra', this.selected_extra, success => {
					this.loading = false
					this.selected_extra = {}
					this.item_empty = true
					this.validateCreation()
					this.extras.push(success)
				}, fail => {
					this.loading = false
				})
			},
			saveExtraField(){
				this.loading = true

				this.$wsc.patchItem(this.path + '/extra', this.selected_extra.id, this.selected_extra, success => {
					this.loading = false
					this.selected_extra = {}
					this.item_empty = true

					this.validateCreation()

					let index = this.extras.findIndex(e => e.id === this.selected_extra.id)

					this.extras.splice(index, 1, success)
				}, fail => {
					this.loading = false
				})
			},
			deleteExtraField(){
				this.loading = true

				this.$wsc.deleteItem(this.path + '/extra', this.item2delete.id, success => {
					this.confirm_dialog = false
					this.extras.splice(this.extras.findIndex(e => e.id === this.item2delete.id), 1)
					this.item2delete = null
					this.loading = false
				}, fail => {
					this.loading = false
				})
			},
			validateCreation(){
				if(JSON.stringify(this.selected_extra).length === 0) {
					this.item_empty = true
				}
				else {
					for(let key in this.selected_extra){
						if(this.selected_extra[key]){
							this.item_empty = false
							break
						}
						else {
							this.item_empty = true
						}
					}
				}

				for(let key in this.fields){
					let type = this.fields[key].type
					let current_val = this.selected_extra[key]

					if(this.fields[key].required && type !== 'multiselect'){
						if(current_val === '' || current_val === null || typeof current_val === 'undefined'){
							this.creation_ok = false
							return
						}
					}
				}

				this.creation_ok = true
			},
			getExtras(){
				this.$wsc.getOptions(this.$route.params, {}, this.path + '/extra', success => {
					this.all_fields = success.fields
					this.fields = this.$tools.filterObject(success.fields, e => !e.read_only)
				}, fail => {

				})
			},
			setUpHooks(){
				this.pevents.wait('open-dialog', () => {
					this.dialog = true
				})

				this.pevents.wait('update', () => {
					this.getExtras()
				})
			}
		},
		created() {
			this.setUpHooks()
			this.getExtras()
		},
		mounted(){
			this.ready = this.extras.length
		}
	}
</script>

<style scoped>

</style>
